<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['marketplaceReviews_selectShop'])"></div>
        {{ $t('marketplaceReviews_selectShop.localization_value.value') }}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content mb-4">
        <div class="custom-row">
        </div>
        <div class="custom-row">
          <div class="custom-col custom-col--83 custom-col--xs-100">
            <div class="m-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['marketplaceReviews_PleaseSelectTheShop'])"></div>
              {{ $t('marketplaceReviews_PleaseSelectTheShop.localization_value.value') }}
            </div>
            <!--<p class="m-0" v-else>Here is the progress of uploading the necessary listings</p>-->
          </div>
          <div class="custom-col custom-col--75 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceReviews_shop'])"></div>
            <DefaultSelect
                :options="shopsFrom"
                :optionsLabel="'shop_name'"
                :label="$t('marketplaceReviews_shop.localization_value.value')"
                v-bind:class="{'ui-no-valid': validateShop}"
                :errorTxt="$t(`error_required.localization_value.value`)"
                :error="validateShop"
                @change="changeShopFrom"
            />
          </div>

        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'common_cancel',
          'common_upload',
        ])"></div>
        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('close')"
        >
          {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
            :value="$t('common_upload.localization_value.value')"
            class="buy-label__btn-i"
            @click.native="upload"
            v-bind:class="{'disabled-btn' : $store.getters.getMarketplaceReviewsBtn}"
        />
      </div>
    </template>
  </modal>

</template>

<script>
import Modal from '../../../../commonModals/Modal.vue'
import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
import {SHOP_TYPES} from "../../../../../staticData/staticVariables";

export default {
  name: "SyncWithMarketplacePopup",
  components: {
    // DotsShadowPreloader,
    Modal,
    MainButton,
    DefaultSelect,
  },

  mixins: [routeFilter],

  props: {
    shopId: [String, Number],
  },

  data() {
    return {
      shopsFrom: [],
      validateShop: false,
      activeShopFrom: {},
    }
  },

  mounted() {
    this.$store.dispatch(
      'fetchShops',
      `?filter[type]=${SHOP_TYPES.ukrainiantreasures.id}&filter[shopStatus]=valid`
    ).then(response => {
      this.shopsFrom = this.getRespPaginateData(response)
    })
  },

  methods: {
    upload() {
      this.validateShop = false
      if (!this.activeShopFrom?.id) {
        this.validateShop = true
        return
      }

      this.$store.dispatch('syncMarketplaceReviews', this.activeShopFrom.id).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */

          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', 'common_notificationRecordCreated')
            this.$emit('reloadTable')
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

      })


    },

    changeShopFrom(val) {
      this.activeShopFrom = val
    }
  }

}

</script>

<style scoped lang="scss">
@import '../../../../../scss/colors';
@import '../../../../../scss/mixins/mixins';

.transfer-etsy {
  width: 100%;

  &__items {

  }

  &__item {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__remove {
    width: 35px;
    min-width: 35px;
    display: flex;
    height: 35px;
    background-image: url("../../../../../assets/img/UI-group/delete-icon-transfer.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    margin-left: -20px;

    @include for-550 {
      margin-left: -15px;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #F8F4EE;
    padding-bottom: 5px;

    @include for-550 {
      max-width: calc(100% - 15px);
    }
  }

  &__name {
    width: 100%;
    max-width: 325px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;

    @include for-550 {
      max-width: calc(100% - 40px);
    }
  }

  &__success {
    width: 18px;
    height: 18px;
    background: url("../../../../../assets/img/common/success-mark.svg") center/contain no-repeat;

    @include for-550 {
      margin-left: 10px;
    }
  }

  &__error {
    width: 18px;
    height: 18px;
    background: url("../../../../../assets/img/common/error-mark.svg") center/contain no-repeat;

    @include for-550 {
      margin-left: 10px;
    }
  }

}


</style>
