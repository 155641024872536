<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_id'])"></div>
        {{ $t('common_id.localization_value.value') }}: {{reviewId}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content mb-4">
        <div class="custom-row">
        </div>
        <div class="custom-row">
          <div class="custom-col custom-col--75 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceReviews_status'])"></div>
            <DefaultSelect
                :options="MARKETPLACE_REVIEWS_STATUSES_ARRAY"
                :otherValue="'translation'"
                :label="$t('marketplaceReviews_status.localization_value.value')"
                :clearable="false"
                :selected="status"
                @change="changeStatus"
            />
          </div>

        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'common_cancel',
          'common_update',
        ])"></div>
        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('close')"
        >
          {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
            :value="$t('common_update.localization_value.value')"
            class="buy-label__btn-i"
            @click.native="upload"
            v-bind:class="{'disabled-btn' : $store.getters.getMarketplaceReviewsBtn}"
        />
      </div>
    </template>
  </modal>

</template>

<script>
import Modal from '../../../../commonModals/Modal.vue'
import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import {MARKETPLACE_REVIEWS_STATUSES_ARRAY} from "../../../../../staticData/staticVariables";

export default {
  name: "ChangeStatusPopup",
  components: {
    // DotsShadowPreloader,
    Modal,
    MainButton,
    DefaultSelect,
  },

  props: {
    reviewId: {
      type: Number,
    },
    currentStatus: {
      type: String,
    },
  },

  data() {
    return {
      status: '',
      MARKETPLACE_REVIEWS_STATUSES_ARRAY: MARKETPLACE_REVIEWS_STATUSES_ARRAY,
    }
  },

  mounted() {
    this.getCurrentStatus()
  },

  methods: {
    upload() {
      let data = {
        'status': this.status.value,
      }

      this.$store.dispatch('updateMarketplaceReviewsStatus', {id: this.reviewId, data: data}).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */

          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', 'common_notificationRecordCreated')

            let reviews = [...this.$store.getters.getMarketplaceReviews]
            reviews.forEach(item => {
              if (item.id === this.reviewId) {
                item.status = this.status.value
              }
            })
            this.$store.commit("setMarketplaceReviews", {marketplaceReviews: reviews})
            this.$emit('close')
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

      })


    },

    changeStatus(val) {
      this.status = val
    },

    getCurrentStatus() {
      this.status = MARKETPLACE_REVIEWS_STATUSES_ARRAY.find(item => {
        return item.value === this.currentStatus
      })
      console.log(this.status);
    },
  }

}

</script>

<style scoped lang="scss">

</style>
