<template>
  <div class="fragment">
    <template v-if="$store.getters.getMarketplaceReviews.length > 0 && !$store.getters.getMarketplaceReviewsLoading">
      <div class="site-table-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'common_id',
          'marketplaceReviews_shop',
          'marketplaceReviews_rating',
          'common_comment',
          'marketplaceReviews_status',
          'common_manage',
          'common_delete',
          'marketplaceReviews_changeStatus',
          'marketplaceReviews_approved',
          'marketplaceReviews_unapproved',
        ])"></span>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>
              {{$t('common_id.localization_value.value')}}
            </th>
            <th>{{$t('marketplaceReviews_shop.localization_value.value')}}</th>
            <th>{{$t('marketplaceReviews_rating.localization_value.value')}}</th>
            <th>{{$t('common_comment.localization_value.value')}}</th>
            <th>{{$t('marketplaceReviews_status.localization_value.value')}}</th>
            <th width="100%"></th>
            <th v-if="isAdmin">{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getMarketplaceReviews"
              :key="index">
            <td>
                #{{item.id}}
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'shop.shop_name'"
              />
            </td>
<!--            <td>{{ item.created_at | moment("DD MMM, YYYY") }}</td>-->
<!--            <td>-->
<!--              {{item.name}}-->
<!--            </td>-->
<!--            <td>-->
<!--              {{item.marketplace_listing_id}}-->
<!--            </td>-->
            <td>
              {{item.rating}}
            </td>

            <td class="white-space-line">
              <div class="table-row">
                <TooltipTextHelper
                    :fullTextWidth="180"
                    :text="item.comment"
                    :paragraph="item.comment"
                />

<!--                <div class="d-flex align-center">-->
<!--                  <v-popover-->
<!--                      class="site-tooltip"-->
<!--                      offset="5"-->
<!--                      placement="top"-->
<!--                      trigger="hover"-->
<!--                  >-->
<!--                    <div class="ellipsis">{{ item.comment }}</div>-->
<!--                    <template slot="popover">-->
<!--                      <p>{{ item.comment }}</p>-->
<!--                    </template>-->
<!--                  </v-popover>-->
<!--                </div>-->
              </div>
            </td>
            <td>
              <div class="d-flex">
                <template v-if="item.status === 'approve'">
                  <StatusIcoBtn :type="'active'" class="mr-1"/> {{$t('marketplaceReviews_approved.localization_value.value')}}
                </template>
                <template v-else>
                  <StatusIcoBtn :type="'cancel-red'" class="mr-1"/> {{$t('marketplaceReviews_unapproved.localization_value.value')}}
                </template>
              </div>
            </td>
            <td></td>
            <td v-if="isAdmin">
              <div class="table-right table-manage-list table-manage-list--small">

                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item">
                    <LinkButton
                            :value="$t('marketplaceReviews_changeStatus.localization_value.value')"
                            :type="'edit'"
                            @click.native="changeStatus(item.id, item.status)"
                    />
                  </template>
                  <template slot="item">
                    <LinkButton
                        :value="$t('common_delete.localization_value.value')"
                        :type="'delete'"
                        @click.native="deleteReview(item.id)"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getMarketplaceReviews"
                 :key="index"
            >
              <MarketplaceReviewsTableMobile
                      :item="item"
                      @changeStatus="changeStatus(item.id, item.status)"
                      @deleteReview="deleteReview(item.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getMarketplaceReviewsCommonList.next_page_url !== null && $store.getters.getMarketplaceReviews.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextMarketplaceReviewsPage}"
                    :count="$store.getters.getMarketplaceReviewsCommonList.total - $store.getters.getMarketplaceReviewsForPage * $store.getters.getMarketplaceReviewsCommonList.current_page < $store.getters.getMarketplaceReviewsForPage ?
                  $store.getters.getMarketplaceReviewsCommonList.total - $store.getters.getMarketplaceReviewsForPage * $store.getters.getMarketplaceReviewsCommonList.current_page:
                  $store.getters.getMarketplaceReviewsForPage"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getMarketplaceReviewsLoading === false && $store.getters.getMarketplaceReviews.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <ChangeStatusPopup
        v-if="isModalChangeStatus"
        :reviewId="orderId"
        :currentStatus="orderStatus"
        @close="isModalChangeStatus = false"
    />

  </div>
</template>

<script>
  // import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import MarketplaceReviewsTableMobile from "./MarketplaceReviewsTableMobile/MarketplaceReviewsTableMobile";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import ChangeStatusPopup from "../../../../popups/ChangeStatusPopup/ChangeStatusPopup";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";


  export default {
    name: "MarketplaceReviewsTable",

    components: {
      TooltipTextHelper,
      ChangeStatusPopup,
      ValueHelper,
      StatusIcoBtn,
      NoResult,
      MarketplaceReviewsTableMobile,
      // MainButton,
      // DefaultInput,
      ShowMore,
      LinkButton,
      ManagerButton,
    },

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },

    mixins: [
      mixinDetictingMobile,
    ],

    computed: {
    },

    // mounted() {
    //   this.isMobile = this.isMobileFunc
    //
    //   window.addEventListener('resize', () => {
    //     this.isMobile = this.isMobileFunc
    //   })
    // },

    data() {
      return {
        orderId: '',
        orderStatus: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        isModalChangeStatus: false,
      }
    },

    methods: {
      deleteReview(id) {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_yes',
          no: 'common_no'
        }

        let confirm = () => {

          this.$store.dispatch('deleteMarketplaceReviews', id).then((response) => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordDeleted')
                this.$emit('reload')
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      changeStatus(id, status) {
        this.orderId = id
        this.orderStatus = status
        this.isModalChangeStatus = true
      }
    }

  }
</script>

<style lang="scss" scoped>

  .products-table .table-manage-list .manager-btn{
    width: 186px;
  }

  .products-table .site-table tbody td:last-child {
    padding-right: 15px;
  }

  .products-table .ellipsis{
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
  }



</style>
