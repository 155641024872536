import { render, staticRenderFns } from "./MarketplaceReviewsTableMobile.vue?vue&type=template&id=405c0f66&scoped=true"
import script from "./MarketplaceReviewsTableMobile.vue?vue&type=script&lang=js"
export * from "./MarketplaceReviewsTableMobile.vue?vue&type=script&lang=js"
import style0 from "./MarketplaceReviewsTableMobile.vue?vue&type=style&index=0&id=405c0f66&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "405c0f66",
  null
  
)

export default component.exports