<template>
  <div class="table-card__item-content"
       :class="{show : show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-number">
        #{{item.id}}
      </div>
      <div class="table-card__item-head-row">
<!--        <div class="table-card__item-head-txt">-->
<!--          {{ item.created_at | moment("DD MMM, YYYY") }}-->
<!--        </div>-->
      </div>
      <!--<div class="table-card__item-head-label">-->
        <!--<div class="table-card__label-circle table-card__item-head-label-i" style="background-color: #9B51E0;">-->
          <!--L-->
        <!--</div>-->
      <!--</div>-->
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
<!--        <div class="table-card__item-row">-->
<!--          <div class="table-card__item-col">-->
<!--            <div class="table-card__item-label">-->
<!--              {{$t('etsyListing_customer.localization_value.value')}}-->
<!--            </div>-->
<!--            <div class="table-card__item-info">-->
<!--              {{item.upc_code}}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="table-card__item-col">-->
<!--            <div class="table-card__item-label">-->
<!--              {{$t('etsyListing_listingId.localization_value.value')}}-->
<!--            </div>-->
<!--            <div class="table-card__item-info">-->
<!--              {{item.sku_code}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('marketplaceReviews_shop.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'shop.shop_name'"
              />
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('marketplaceReviews_rating.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.rating}}
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('common_comment.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <template>
                <v-popover
                    class="site-tooltip"
                    offset="5"
                    placement="top"
                    trigger="hover"
                >
                  <div class="ellipsis">{{ item.comment }}</div>
                  <template slot="popover">
                    <p>{{ item.comment }}</p>
                  </template>
                </v-popover>
              </template>
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('marketplaceReviews_status.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <div class="d-flex">
                <template v-if="item.status === 'approve'">
                  <StatusIcoBtn :type="'active'" class="mr-1"/> {{$t('marketplaceReviews_approved.localization_value.value')}}
                </template>
                <template v-else>
                  <StatusIcoBtn :type="'cancel-red'" class="mr-1"/> {{$t('marketplaceReviews_unapproved.localization_value.value')}}
                </template>
              </div>
            </div>
          </div>
        </div>


        <div class="table-card__item-btn">
          <div v-if="isAdmin" class="table-card__item-btn-i">
            <LinkButton
                :value="$t('marketplaceReviews_changeStatus.localization_value.value')"
                :type="'edit'"
                @click.native="$emit('changeStatus')"
            />
          </div>
          <div v-if="isAdmin" class="table-card__item-btn-i">
            <LinkButton
                :value="$t('common_delete.localization_value.value')"
                :type="'delete'"
                @click.native="$emit('deleteReview')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import StatusIcoBtn from "@/components/UI/status/StatusIcoBtn/StatusIcoBtn";
  export default {
    name: "MarketplaceReviewsTableMobile",

    components: {StatusIcoBtn, ValueHelper, LinkButton},

    props: {
      item: Object,
    },

    data() {
      return {
        show: false,
      }
    },
  }
</script>

<style scoped>
.table-card .ellipsis {
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  display: inline-block;
}
</style>
