
<template>
  <div class="fragment">


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator"
           v-bind:class="{'loading-tabs': $store.getters.getMarketplaceReviewsLoading === true}">

      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton v-if="isAdmin" :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">

      </div>
      <div class="btn-right-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['marketplaceReviews_sync'])"></div>
        <MainButton class="btn-fit-content ml-2"
                    v-if="isAdmin || (!isAdmin && userShopId > 0)"
                    v-bind:class="{'disabled-btn' : $store.getters.getMarketplaceReviewsBtn}"
                    :value="$t('marketplaceReviews_sync.localization_value.value')"
                    :ico="true"
                    @click.native="reviewsSync"
        >
          <template slot="ico">
            <TransferIconSVG class="btn-icon-plus" />
          </template>
        </MainButton>
      </div>
    </div>

    <SyncWithMarketplacePopup
        v-if="isModalImportPopup"
        @close="changeImportPopup(false)"
        @reloadTable="closeReload"
    />
  </div>
</template>

<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  // import ImportPopup from "../../../../popups/ImportPopup/ImportPopup";
  import TransferIconSVG from '../../../../../../../../public/img/UI-group/transfer-ico-btn.svg?inline'
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import SyncWithMarketplacePopup from "../../../../popups/SyncWithMarketplacePopup/SyncWithMarketplacePopup";


  export default {
    name: "MarketplaceReviewsHead",
    components: {
      SyncWithMarketplacePopup,
      MainButton,
      TransferIconSVG,
      // ImportPopup,
      ToggleFilterButton,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      userShopId: Number,
    },

    watch: {

    },

    created() {

    },

    data() {
      return {
        isModalImportPopup: false,
      }
    },



    methods: {


      changeTab(val) {
        this.$emit('changeTab', val)
      },

      changeImportPopup(val){
        this.isModalImportPopup = val
      },

      closeReload() {
        this.isModalImportPopup = false
        this.$emit('reloadTable')
      },

      reviewsSync() {
        if (!this.isAdmin) {
          this.$store.dispatch('syncMarketplaceReviews', this.userShopId).then((response) => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordCreated')
                this.$emit('reloadTable')
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })
        }
        else {
          this.isModalImportPopup = true
        }
      }
    }
  }
</script>

<style scoped>

.likeTooltipBtn{
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-20px, -8px);
}

.likeTooltipBtn__item{
  padding: 0;
  width: 20px;
  margin-top: -18px;
  margin-left: 4px;
}

  @media (max-width: 1220px){
    .print-btn__btn {
      padding-right: 16px;
      padding-left: 0;
    }
  }

@media (max-width: 991px) {
  .likeTooltipBtn {
    position: absolute;
    top: -8px;
    right: 0;
    transform: none;
  }
}
</style>
